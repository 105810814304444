import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Ласкаво просимо до Café Serendipity!
			</title>
			<meta name={"description"} content={"ДЕ КОЖЕН КОВТОК ВИКЛИКАЄ ПОСМІШКУ"} />
			<meta property={"og:title"} content={"Ласкаво просимо до Café Serendipity!"} />
			<meta property={"og:description"} content={"ДЕ КОЖЕН КОВТОК ВИКЛИКАЄ ПОСМІШКУ"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-3.jpg?v=2024-06-20T18:59:53.403Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ласкаво просимо до нашого меню
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					У Café Serendipity ми пишаємося тим, що пропонуємо різноманітний і чудовий вибір напоїв і частувань. Кожна страва в нашому меню створена з любов’ю та турботою, щоб кожен ковток і шматок приносили мить радості. Від нашої ароматної кави до нашої свіжоспеченої випічки, кожен знайде щось для себе.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-3.jpg?v=2024-06-20T18:59:53.401Z) bottom/cover no-repeat scroll padding-box"
				lg-min-height="400px"
				lg-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 75%/cover no-repeat scroll padding-box"
				md-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 100% 100%/cover no-repeat scroll padding-box"
				sm-background="#FFFFFF url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/bg-green.jpg?v=2021-08-26T00:39:08.141Z) 50% 100%/cover no-repeat scroll padding-box"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18:59:53.396Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/2-2.jpg?v=2024-06-20T18%3A59%3A53.396Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Ми віримо, що чудова кава починається з чудових зерен. Наше меню кави містить ряд варіантів, кожен з яких підкреслює унікальний смак наших ретельно відібраних зерен. Незалежно від того, чи є ви поціновувачем кави чи випадковим любителем кави, ви знайдете напій на свій смак.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Асортимент пропозицій
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Наш доброзичливий та досвідчений персонал допоможе вам на кожному етапі процесу та відповість на будь-які ваші запитання.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Класика еспресо{"\n"}
								<br />
							</Strong>
							Еспресо: Міцна, смілива порція чистої кави, ідеальна для швидкого підбору.{"\n"}
							<br />
							Капучіно: Гармонійне поєднання еспресо, парного молока та пінистої верхівки, що забезпечує збалансований смак.
							<br />
							{"\n"}Латте: Гладкий і кремовий, наш латте поєднує насичений еспресо з паровим молоком для чудового враження.{"\n"}
							<br />
							Макіато: Еспресо з відтінком молочної піни, що забезпечує ідеальний баланс смаків.
							<br />
							{"\n"}Американо: Еспресо, розбавлений гарячою водою, забезпечує м’який насичений смак кави.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Спеціальна кава{"\n"}
								<br />
							</Strong>
							Карамельний латте з морською сіллю: Розкішний латте з відтінком карамелі та морською сіллю для унікальної нотки.{"\n"}
							<br />
							Lavender Honey Cappuccino: Квітковий і солодкий капучіно, наповнений натуральною лавандою та медом.{"\n"}
							<br />
							Мокко: насичена суміш еспресо, шоколаду та парного молока зі збитими вершками.{"\n"}
							<br />
							Vanilla Bean Latte: Гладке еспресо в поєднанні з тонкою солодкістю стручок ванілі.{"\n"}
							<br />
							Пряний чай латте: Ароматна суміш чорного чаю, спецій і парного молока ідеально підходить для затишного дня.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Класичні чаї
								<br />
							</Strong>
							{"\n"}Earl Grey: Вічний чорний чай із відтінком бергамоту.{"\n"}
							<br />
							Зелений чай: Легкий і освіжаючий, насичений антиоксидантами.{"\n"}
							<br />
							Ромашка: Заспокійливий трав’яний чай, ідеальний для релаксації.{"\n"}
							<br />
							М’ята перцева: Освіжаючий і підбадьорливий трав’яний настій.{"\n"}
							<br />
							Англійський сніданок: Міцний чорний чай, ідеальний для початку дня.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Спеціальні інфузії
								<br />
							</Strong>
							{"\n"}Matcha Latte: Вершковий і землистий, виготовлений із високоякісного порошку матча та парного молока.{"\n"}
							<br />
							Імбирний чай з куркумою: Тепла і пряна суміш, відома своїми корисними властивостями.{"\n"}
							<br />
							Чай з гібіскусу: Терпкий і яскравий трав’яний чай, насичений смаком і кольором.{"\n"}
							<br />
							Лимонно-імбирний чай: пікантна та підбадьорлива суміш, ідеальна для освіжаючої перерви.{"\n"}
							<br />
							Rooibos Vanilla: М’який варіант без кофеїну з відтінком ванілі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Випічка
Круасани
								<br />
							</Strong>
							Масляні та пластівці, доступні у класичному, мигдальному та шоколадному.{"\n"}
							<br />
							Мафіни: м’які та вологі, зі смаком чорниці, бананового горіха та шоколадної стружки.{"\n"}
							<br />
							Булочки: Ідеально запечені з розсипчастою текстурою, доступні з різними смаками, такими як журавлина та лимон.{"\n"}
							<br />
							Датський: солодкий і фруктовий, з начинкою, як-от яблуко, вишня та сир.{"\n"}
							<br />
							Печиво: свіжоспечене та доступне у класичному вигляді з шоколадною крихтою, вівсяними пластівцями з родзинками тощо.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Легкі закуски{"\n"}
								<br />
							</Strong>
							Ремісничі сендвічі: виготовлені зі свіжих інгредієнтів і доступні у таких варіантах, як авокадо з індички, капрезе та класична шинка з сиром.{"\n"}
							<br />
							Кіші: пікантні та ситні, зі шпинатом і фетою, беконом і чеддером.{"\n"}
							<br />
							Салати: свіжі та хрусткі, такі як «Цезар», садовий і грецький.{"\n"}
							<br />
							Фруктові чашки: корисний і освіжаючий варіант із сумішшю сезонних фруктів.
Йогуртові парфе:  Вершковий йогурт із шарами граноли та свіжих ягід.
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Щоб ознайомитися з усіма нашими пропозиціями, відвідайте нас або зв’яжіться з нами. Якщо у вас є якісь проблеми щодо дієти чи особливі побажання, не соромтеся зв’язатися з нами. Ми тут, щоб зробити ваш досвід ідеальним.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18:59:53.413Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66746f808d4a0c002030b7aa/images/1-1.jpg?v=2024-06-20T18%3A59%3A53.413Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Це меню – лише короткий огляд того, що ми пропонуємо. Незалежно від того, чи бажаєте ви міцного еспресо, заспокійливого чаю чи смачної випічки, Café Serendipity — це ваше місце призначення. Приходьте сьогодні та побалуйте себе найкращим кафе у місті.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});